import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Media from "react-media"
import LayoutDirectory from "components/layoutDirectory"
import IconFacebook from "components/svg/IconFacebook"
import IconInstagram from "components/svg/IconInstagram"
import IconTwitter from "components/svg/IconTwitter"
import IconYouTube from "components/svg/IconYouTube"
import { Button } from "components/button"
import { noise } from "components/css/noise"

const StyledPageWrap = styled.div`
  position: relative;
  z-index: 1;
  max-width: 40em;
  margin-right: auto;
  margin-left: auto;
`

const StyledPageContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 2em 0;

  a:not(${Button}) {
    text-decoration: underline;
    .can-hover &:hover,
    &:active {
      color: var(--color-gray-text)
    }
  }
  & > div {
    margin-bottom: 3em;
  }
  @media ( min-width: 60em ) and ( max-width: 64em ) {
    padding-top: 3em;
  }
  @media ( min-width: 64em ) {
    grid-column: 1;
    grid-row: 1;
  }
`


const FaqPage = ({ data }) => {

    return (
      <>
        <Helmet>
            <title>An Apology from Ian | {data.site.siteMetadata.title}</title>
        </Helmet>
        <LayoutDirectory>
          <StyledPageWrap>
            <StyledPageContent>
              <h1 className="pageTitle">An Apology from Ian</h1>
              <div>
                <p>You convince artists to invest their time, brand, effort, audience, trust and creativity into your project, it’s important to communicate with them fully, ensure they get royalty statements and paid on time, answer their questions quickly. I failed to do these things. The label I created to run the project and the subcontractor I hired to do these things surely could have done much better. I apologize unequivocally.</p>
                <p>My goal has been from day one to recruit musicians, as many as possible, to help disseminate reporting that I think is worth trying to get out further, globally. We have had amazing success in this goal. Millions of people have read the stories and watched videos of the reporting that likely would not have otherwise. I’ve never made a cent from the music nor would I as that’s not the project’s purpose.</p>
                <p>The aim has been to convert stories into a different language, namely music, as a creative means to grab the attention of a younger and more diverse audience — the very audience sometimes missed by legacy outlets. I thought, and still do, this was a new way to circulate journalism via non-news platforms. Any money that came in went toward publishing new albums and recruiting more artists.</p>
                <p>That said, if musicians now feel (or previously felt) like the project got too large or didn’t perform well for them, the ethical way forward, seems to me, is clear: we hand you back your music and you’re free to publish elsewhere on your own. Several days ago Synesthesia alerted artists of this very option.</p>
              </div>
            </StyledPageContent>
          </StyledPageWrap>
        </LayoutDirectory>
      </>
    )
}

export const query = graphql`
    query {
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`

export default FaqPage